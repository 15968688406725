<template>
  <div class="services-page">
    <page-header
      :cover="require('../assets/images/services-cover.png')"
      :title="$t('ourServices')"
      :desc="$t('ourServicesDesc')"
    />
    <div class="services mb-4">
      <div class="container text-center">
        <div class="serv-cards">
          <service-card
            v-for="(service, serind) in services"
            :key="serind"
            :service="service"
            @openService="toggelModal"
          />
        </div>
      </div>
    </div>
    <!-- <let-us-help /> -->
    <contact-us />
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <modal
        :service="modalData"
        :link="link"
        :btnName="btnName"
        v-if="activeModal"
        @closeModal="toggelModal"
      />
    </transition>
  </div>
</template>

<script>
import ContactUs from "../components/Layout/ContactUs.vue";
// import LetUsHelp from "../components/Layout/LetUsHelp.vue";
import ServiceCard from "../components/Layout/ServiceCard.vue";
import PageHeader from "../components/misc/PageHeader.vue";
import Modal from "../components/Layout/Modal.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    PageHeader,
    ServiceCard,
    ContactUs,
    // LetUsHelp,
    Modal,
  },
  data() {
    return {
      modalData: null,
      link: null,
      btnName: null,
      activeModal: false,
    };
  },
  methods: {
    toggelModal(ser) {
      this.activeModal = !this.activeModal;
      if (ser) {
        this.modalData = ser;
        this.btnName = "See Use Cases";
        this.link = {
          name: "HowToDeliver",
        };
      }
    },
  },
  computed: {
    ...mapGetters(["services"]),
  },
};
</script>

<style>
</style>